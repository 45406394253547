import gql from "graphql-tag";

export const GET_CLIENT_CONTACTS = gql`
  query GetClientContacts($id: uuid) {
    contacts(where: { clientID: { _eq: $id } }, order_by: { firstName: asc }) {
      id
      firstName
      lastName
      middleName
      email
      phone
      user {
        id
        disabled
        role
      }
      address {
        id
        address1
        address2
        city
        country
        postalCode
        state
      }
    }
  }
`;

export const GET_CLIENT_CONTACT_DETAILS = gql`
  query GetClientContactDetails($id: uuid!) {
    contact: contacts_by_pk(id: $id) {
      id
      firstName
      lastName
      middleName
      email
      phone
      user {
        id
        disabled
        role
        lastLogin
      }
      address {
        id
        address1
        address2
        city
        country
        postalCode
        state
      }
      locations {
        clientLocationID
      }
      client {
        id
        name
        locations {
          id
          name
        }
      }
    }
  }
`;

export const CHECK_FOR_USER_BY_EMAIL = gql`
  query CheckForUserByEmail($email: String) {
    users(where: { username: { _eq: $email } }) {
      id
      role
    }
  }
`;
