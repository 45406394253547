<template>
  <contacts-add-edit v-model="contact" title="Edit Client Contact" :entity-name="client.name" client-contact :edit="true" @onSave="updateContactInfo" @onLocationsChanged="updateLocations">
    <template #description>
      <p>An email will be sent to this contact to create an account used to login to the Client Portal. Contacts added to the client will have access to all locations.</p>
    </template>
  </contacts-add-edit>
</template>
<script>
import { GET_CLIENT_CONTACT_DETAILS } from "@/modules/shared/contacts/graph/queries";
import { ADD_LOCATION_ASSIGNMENTS, REMOVE_LOCATION_ASSIGNMENTS, UPDATE_CLIENT_CONTACT } from "@/modules/shared/contacts/graph/mutations";
import ContactsAddEdit from "@/modules/shared/contacts/ContactsAddEdit";
import { GET_CLIENT_DETAILS } from "@/modules/admin/clients/graph/queries";

export default {
  components: { ContactsAddEdit },
  data: () => ({
    client: {
      name: "",
    },
    contact: {
      user: {},
      address: {
        country: "", // This has to be set to empty string or the plugin we used will reset the state value to empty string <shrug>
      },
      client: {
        location: [],
      },
    },
  }),
  methods: {
    updateLocations(locations) {
      this.contact.locations = locations.map((location) => ({ clientLocationID: location }));
    },
    updateContactInfo() {
      const phone = this.contact.phone.replace(/\D/g, "");
      return this.$apollo
        .mutate({
          mutation: UPDATE_CLIENT_CONTACT,
          variables: {
            contactID: this.contact.id,
            firstName: this.contact.firstName,
            lastName: this.contact.lastName,
            phone,
            email: this.contact.email.toLowerCase(),
            addressID: this.contact.address.id,
            address1: this.contact.address.address1,
            address2: this.contact.address.address2,
            city: this.contact.address.city,
            postalCode: this.contact.address.postalCode,
            state: this.contact.address.state,
            country: this.contact.address.country,
            userID: this.contact.user.id,
            role: this.contact.user.role,
          },
        })
        .then(() => this.updateContactLocationAssignment())
        .finally(() => this.$router.back());
    },
    updateContactLocationAssignment() {
      const add = [...this.contact.locations.map((location) => location.clientLocationID)];
      const remove = [];
      this.contact.client.locations.filter((location) => !add.includes(location.id)).forEach((id) => remove.push(id.id));
      if (remove.length > 0) {
        remove.forEach(
          async (clientLocationID) =>
            await this.$apollo.mutate({
              mutation: REMOVE_LOCATION_ASSIGNMENTS,
              variables: {
                contactID: this.$route.params.contactId,
                clientLocationID,
              },
            })
        );
      }
      if (add.length > 0) {
        const arr = add.map((id) => ({ contactID: this.$route.params.contactId, clientLocationID: id }));
        this.$apollo.mutate({
          mutation: ADD_LOCATION_ASSIGNMENTS,
          variables: {
            objects: arr,
          },
        });
      }
    },
  },
  apollo: {
    contact: {
      fetchPolicy: "network-only",
      query: GET_CLIENT_CONTACT_DETAILS,
      variables() {
        return {
          id: this.$route.params.contactId,
        };
      },
      update: (data) => data.contact,
      result({ data }) {
        this.locationAssignments = data.contact.locations.map((location) => location.clientLocationID);
        if (!data.contact.user) {
          this.contact.user = {};
        }
      },
      skip() {
        return !this.$route.params.contactId;
      },
    },
    client: {
      query: GET_CLIENT_DETAILS,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update: (data) => data.clients[0],
      skip() {
        return !this.$route.params.contactId;
      },
    },
  },
};
</script>
